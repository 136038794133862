window.$ = window.jQuery = require('jquery');

$(function(){
	let $loginform = $('form.login-form');
	let $forgetform = $('form.forget-form');
	let $forgetlink = $('a#forget-password');
	let $backbutton = $('button#back-btn');

	
	$forgetlink.on('click', function(){
		$loginform.css({"transform": "translateX(-380px)", opacity: 0});
		$forgetform.css({"transform": "translateX(0px)", opacity: 1});
	});

	$backbutton.on('click', function(){
		$forgetform.css({"transform": "translateX(380px)", opacity: 0});
		$loginform.css({"transform": "translateX(0px)", opacity: 1});
	});
});